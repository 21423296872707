import React, { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import api from 'api';
import Swal from 'sweetalert2';
import { useAuth } from 'contexts/AuthContext';

function SearchTableRBL(props) {
  useAuth();
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,

    setGlobalFilter,
    state,
  } = tableInstance;

  const { pageIndex, pageSize } = state;

  async function deleteURL(url) {
    const response = await api.delete(`/rbl/delete/${encodeURIComponent(url)}`);
    if (response.data.status === 'success') {
      Swal.fire({
        title: 'Success!',
        text: `URL deleted!`,
        icon: 'success',
        confirmButtonText: 'OK',
      }).then(() => {
        window.location.reload();
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: `Deleting URL was not successful! Error: ${data.message}`,
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  }

  return (
    <div className="h-full w-full">
      <header className="relative flex items-center justify-between">
        {/* Search */}
        <div
          onChange={(e) => setGlobalFilter(e.target.value)}
          className="flex w-3/4 items-center rounded-md bg-white p-[8px] pt-[18px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:ml-3 md:w-[400px]"
        >
          <div className="flex h-9 w-[400px] flex-grow items-center rounded-md bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
            <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
            <input
              type="text"
              placeholder="Search...."
              className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
            />
          </div>
        </div>
      </header>
      {/* table */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className=" items-center border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=" px-4 pb-[10px] text-start text-xs text-gray-600"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="w-full" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'URL') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'Range') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'Type') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'Actions') {
                      data = (
                        <button
                          onClick={() => deleteURL(cell.row.original.url)}
                          className="rounded-xl bg-gradient-to-br from-[#FF416C] to-[#FF4B2B] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FF416C]/50"
                        >
                          Delete
                        </button>
                      );
                    }
                    return (
                      <td
                        className=" mt-[20px] px-4 py-[16px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-20 w-full items-center justify-between px-6">
        <div className="text-sm text-gray-700">
          Showing {pageSize * pageIndex + 1} to{' '}
          {pageSize * (pageIndex + 1) <= tableData.length
            ? pageSize * (pageIndex + 1)
            : tableData.length}{' '}
          of {tableData.length} entries
        </div>

        <div>
          <button className="h-10 w-10 rounded-full bg-brand-500 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            1
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchTableRBL;
