import { Routes, Route, Navigate } from 'react-router-dom';

import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import 'assets/css/Plugins.css';
import { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from 'contexts/AuthContext';

const App = () => {
  // Create a new context
  const [themeApp, setThemeApp] = useState({
    '--background-100': '#FFFFFF',
    '--background-900': '#070f2e',
    '--shadow-100': 'rgba(112, 144, 176, 0.08)',
    '--color-50': '#E9E3FF',
    '--color-100': '#C0B8FE',
    '--color-200': '#A195FD',
    '--color-300': '#8171FC',
    '--color-400': '#7551FF',
    '--color-500': '#422AFB',
    '--color-600': '#3311DB',
    '--color-700': '#2111A5',
    '--color-800': '#190793',
    '--color-900': '#11047A',
  });
  //eslint-disable-next-line
  const theme_green = {
    '--color-50': '#E1FFF4',
    '--color-100': '#BDFFE7',
    '--color-200': '#7BFECE',
    '--color-300': '#39FEB6',
    '--color-400': '#01F99E',
    '--color-500': '#01B574',
    '--color-600': '#01935D',
    '--color-700': '#016B44',
    '--color-800': '#00472D',
    '--color-900': '#002417',
  };
  //eslint-disable-next-line
  const theme_orange = {
    '--color-50': '#FFF7EB',
    '--color-100': '#FFF1DB',
    '--color-200': '#FFE2B8',
    '--color-300': '#FFD28F',
    '--color-400': '#FFC46B',
    '--color-500': '#FFB547',
    '--color-600': '#FF9B05',
    '--color-700': '#C27400',
    '--color-800': '#855000',
    '--color-900': '#422800',
    '--color-950': '#1F1200',
  };
  //eslint-disable-next-line
  const theme_red = {
    '--color-50': '#FCE8E8',
    '--color-100': '#FAD1D1',
    '--color-200': '#F4A4A4',
    '--color-300': '#EF7676',
    '--color-400': '#EA4848',
    '--color-500': '#E31A1A',
    '--color-600': '#B71515',
    '--color-700': '#891010',
    '--color-800': '#5B0B0B',
    '--color-900': '#2E0505',
    '--color-950': '#170303',
  };
  //eslint-disable-next-line
  const theme_blue = {
    '--color-50': '#EBEFFF',
    '--color-100': '#D6DFFF',
    '--color-200': '#ADBFFF',
    '--color-300': '#8AA3FF',
    '--color-400': '#6183FF',
    '--color-500': '#3965FF',
    '--color-600': '#0036FA',
    '--color-700': '#0029BD',
    '--color-800': '#001B7A',
    '--color-900': '#000D3D',
    '--color-950': '#00071F',
  };
  //eslint-disable-next-line
  const theme_teal = {
    '--color-50': '#EBFAF8',
    '--color-100': '#D7F4F2',
    '--color-200': '#AAE9E4',
    '--color-300': '#82DED6',
    '--color-400': '#59D4C9',
    '--color-500': '#33C3B7',
    '--color-600': '#299E94',
    '--color-700': '#1F756E',
    '--color-800': '#144D48',
    '--color-900': '#0B2826',
    '--color-950': '#051413',
  };
  //eslint-disable-next-line
  const theme_purple = {
    '--color-50': '#EFEBFF',
    '--color-100': '#E9E3FF',
    '--color-200': '#422AFB',
    '--color-300': '#422AFB',
    '--color-400': '#7551FF',
    '--color-500': '#422AFB',
    '--color-600': '#3311DB',
    '--color-700': '#02044A',
    '--color-800': '#190793',
    '--color-900': '#11047A',
  };
  const [mini, setMini] = useState(false);
  setTimeout(() => setThemeApp(theme_teal), 0);
  // When the theme state changes, this effect will update the CSS variables in the document's root element
  useEffect(() => {
    let color;
    for (color in themeApp) {
      document.documentElement.style.setProperty(color, themeApp[color]);
    }
    document.body.classList.add('dark');
    //eslint-disable-next-line
  }, [themeApp]);
  return (
    <ChakraProvider theme={themeApp}>
      <AuthProvider>
        <Routes>
          <Route path="auth/*" element={<AuthLayout />} />
          <Route
            path="/*"
            element={
              <AdminLayout
                setMini={setMini}
                mini={mini}
                theme={themeApp}
                setTheme={setThemeApp}
              />
            }
          />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </AuthProvider>
    </ChakraProvider>
  );
};

export default App;
