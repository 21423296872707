import React, { useState, useEffect, useCallback } from 'react';
import Cookies from 'js-cookie';
import api from '../api.js';
const AuthContext = React.createContext({});

export const useAuth = () => React.useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState({});

  useEffect(() => {
    setLoading(true);

    const userSessionCookie = Cookies.get('Blade Bill');

    if (userSessionCookie) {
      const { sessionToken } = JSON.parse(userSessionCookie);

      api
        .post('/auth/validate', { sessionToken })
        .then((response) => {
          if (response.data.status === 'success') {
            setToken(sessionToken);
          } else {
            Cookies.remove('Blade Bill');
          }
        })
        .catch((error) => {
          console.error('Token validation error: ', error);
          Cookies.remove('Blade Bill');
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    setLoading2(true);

    if (userSessionCookie) {
      const { sessionToken } = JSON.parse(userSessionCookie);

      api
        .post('/auth/user/live', { sessionToken })
        .then((response) => {
          if (response.data.status === 'success') {
            setUser(response.data.user);
          } else {
            Cookies.remove('Blade Bill');
          }
        })
        .catch((error) => {
          console.error('Token validation error: ', error);
          Cookies.remove('Blade Bill');
        })
        .finally(() => {
          setLoading2(false);
        });
    } else {
      setLoading2(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = useCallback((data) => {
    let sessionToken = data.sessionToken;
    let user = data.user;

    // Verify and decode the JWT before setting it
    try {
      setToken(sessionToken);
      let expirationTime = new Date();
      expirationTime.setTime(expirationTime.getTime() + 30 * 60 * 1000);
      Cookies.set('Blade Bill', JSON.stringify({ sessionToken }), {
        expires: expirationTime,
      });
      setUser(user);
    } catch (error) {
      console.error('JWT verification failed:', error);
      setToken(null);
      setUser(null);
      Cookies.remove('Blade Bill');
    }
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setUser(null);
    Cookies.remove('Blade Bill');
  }, []);

  return (
    <AuthContext.Provider
      value={{ loading, loading2, token, user, login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
