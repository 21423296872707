import React, { useState, useEffect } from 'react';
import { useAuth } from 'contexts/AuthContext';
import SearchTableOrders from './components/SearchTableRBL';
//import Widget from './components/Widget';
import Card from 'components/card';
import api from 'api';
import { Spinner } from '@chakra-ui/spinner';
import { Select } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import InputField from 'components/fields/InputField';

const Dashboard = () => {
  useAuth();
  const [RBL, setRBL] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  useEffect(() => {
    api
      .get('/rbl/get_rbl')
      .then((response) => {
        if (response.data.status === 'success') {
          setRBL(response.data.rbl);
        }
      })
      .catch((error) => {
        console.error('Error getting RBL: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const columnsDataOrders = [
    {
      Header: 'URL',
      accessor: 'url',
    },
    {
      Header: 'Range',
      accessor: 'range',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Actions',
      accessor: 'actions',
    },
  ];
  const handleSubmit = (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const form = new FormData(e.target);
    const url = form.get('url');
    const range = form.get('range');
    const type = form.get('type');
    const data = JSON.stringify({
      reason: 'None',
    });
    api
      .post('/rbl/add_url', { url, range, type, data })
      .then(({ data }) => {
        setButtonLoading(false);
        if (data.status === 'success') {
          Swal.fire({
            title: 'Success!',
            text: `URL Added!`,
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            window.location.reload();
          });
        } else if (data.status === 'error') {
          Swal.fire({
            title: 'Error!',
            text: `Adding URL was not successful! Error: ${data.message}`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Adding URL was not successful! Please contact your administrator.`,
            icon: 'error',
            confirmButtonText: 'OK',
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="h-full w-full rounded-md">
      {/* Reserved for future statistics */}
      {loading ? (
        <div className="flex h-32 items-center justify-center">
          <Spinner
            size="xl"
            thickness="6px"
            style={{ height: 100, width: 100 }}
          />
        </div>
      ) : (
        <>
          <Card extra={'w-full h-full bg-white mt-3'}>
            <SearchTableOrders
              tableData={RBL}
              columnsData={columnsDataOrders}
            />
          </Card>
          <div className="mt-5 gap-5">
            <Card extra={'w-full pb-10 p-4 h-full'}>
              <form onSubmit={handleSubmit}>
                <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                  Add URL
                </h3>
                <p className="mb-9 ml-1 text-base text-gray-600">
                  Add a URL to the RBL
                </p>
                {/* DB Name */}
                <InputField
                  field_name="url"
                  extra="mb-3"
                  label="URL*"
                  placeholder="https://google.com"
                  id="url"
                  type="url"
                  required
                />
                <Select name="range" id="range" placeholder="Select Range" style={{ background: "rgba(0, 0, 0, 0.5)" }} className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none mb-3">
                  <option value="domain">Domain</option>
                  <option value="url">URL</option>
                </Select>
                <Select name="type" id="type" placeholder="Select Type" style={{ background: "rgba(0, 0, 0, 0.5)" }} className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none mb-3">
                  <option value="blocked" style={{ background: "red", color: "#000"}}>Blocked</option>
                  <option value="ok" style={{ background: "green", color: "#000"}}>Ok</option>
                  <option value="bypass" style={{ background: "yellow", color: "#000"}}>Bypass</option>
                </Select>
                {buttonLoading ? (
                  <button
                    className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                    disabled
                  >
                    <Spinner
                      size="xl"
                      thickness="2px"
                      style={{ height: 14, width: 14 }}
                    />
                  </button>
                ) : (
                  <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                    Add URL
                  </button>
                )}
              </form>
            </Card>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
