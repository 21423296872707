import { MdHome } from 'react-icons/md';

// Admin Imports
import Dashboard from 'views/dashboard';
import Login from 'views/auth/Login.jsx';
import NotFound from 'views/404'

const routes = [
  {
    name: 'Dashboard',
    layout: '/admin',
    path: 'dashboard',
    icon: <MdHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: 'Login',
    layout: '/auth',
    path: 'login',
    component: <Login />,
    sidebarLink: false,
  },
  {
    name: '404',
    layout: '/admin',
    path: '404',
    component: <NotFound />,
    sidebarLink: false
  }
];

export default routes;
